<template>
<div>
  <client-Modal :id="selectedClient" :dialog.sync="dialogs.client" @closeDialog="closeClientDialog" @newClient="onNewClient" @clientUpdated="onClientUpdate"/>
</div>
</template>

<script>

import ClientModal from "../../components/modals/clientModal";

export default {
  name: 'clientListener',
  components:{ClientModal},
  data() {
    return {
      //Client
      dialogs: {client: false},
      selectedClient: null,

      listeners: {
        openClient: () => {
        }
      },
    }
  },
  created() {
    let $this = this;
    this.$root.$on('crm::client::select', this.listeners.openClient = (id) => {
      console.log('crm::client::select');
      this.onClientSelect(id);
    });
  },
  beforeDestroy() {
    this.$root.$on('crm::client::select', this.listeners.openClient);
  },
  methods: {
    closeClientDialog() {
      let $this = this;
      $this.dialogs.client = false;
      setTimeout(() => $this.selectedClient = null, 500);
    },
    onClientSelect(id) {
      console.log("onClientSelect(" + id + ")");
      this.selectedClient = id;
      this.dialogs.client = true;
      this.$root.$emit('bv::hide::popover');
    },
    onNewClient(clientId) {
      this.$root.$emit('crm::client-table::refresh');
      console.log('Clients -> onNewClient()', clientId)
      clientId = parseInt(clientId)
      if (!isNaN(clientId) && clientId > 0) {
        this.selectedClient = clientId
      }
    },
    onClientUpdate(status) {
      this.$root.$emit('crm::client-table::refresh');
      console.log('onClientUpdate was called with status: ', status)
      if (status === true) {
        this.$emit('crm::calendar::refresh')
      }
    },
  },
}
</script>

<style scoped>

</style>
