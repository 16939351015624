<template>
  <div id="clientsDocuments" style="height: 100%">
    <b-row style="height: 100%">
      <b-col cols="2" class="border-right" style="height: 100%">
        <div>
          <b-button id="upload-doc" variant="primary" block @click="$emit('add-new-event', true)">
            <i class="fas fa-upload mr-2"></i>
            {{ msg('Upload') }}
          </b-button>

          <b-popover target="upload-doc" placement="leftbottom" id="upload-doc-file">
            <template slot="title">
              <b-row>
                <b-col cols="9">
                  {{ msg('Upload') }}
                </b-col>
                <b-col cols="3">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-link style="color: inherit;" @click="$root.$emit('bv::hide::popover')" :title="msg('Close')">
                      <feather-icon icon="XIcon"/>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </template>
            <div class=" mt-2">
              <b-form-file
                  v-model="upload.file"
                  :state="Boolean(upload.file)"
                  :placeholder="`${msg('Choose a file')}...`"
                  :drop-placeholder="msg('Drop file here')+'...'"
              ></b-form-file>
              <div>
                <v-select v-if="newType !== true"
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="upload.group"
                    :options="documentGroups"
                    autocomplete
                    class="w-100"
                    label="name"
                    :reduce="(role) => role.id"
                    :clearable="false"
                ></v-select>

                <b-form-input id="client-mobile" v-if="newType !== false" v-model="upload.group" />
              </div>

              <b-form-checkbox
                  v-model="newType"
                  class="custom-control-primary"
                  @input="upload.group=''"
              >
                {{ msg('New category') }}
              </b-form-checkbox>

              <b-button class="mt-2" @click="uploadFile">{{ msg('Upload') }}</b-button>
            </div>
          </b-popover>
        </div>

        <div class="mt-3">
          <h5 class="app-label section-label mb-1">
            <span class="align-middle">{{ msg('Categories') }}</span>
          </h5>
          <b-form-checkbox
              v-model="checkAllTypes"
              class="mb-1"
          >
            {{ msg('View All') }}
          </b-form-checkbox>
          <b-form-group>
            <b-form-checkbox-group
                v-model="selectedTypes"
                name="event-filter"
                stacked
            >
              <b-form-checkbox
                  v-for="group in documentGroups" :key="group"
                  name="event-filter"
                  :value="group"
                  class="mb-1 custom-control-primary"
              >
                {{ group }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>

      </b-col>
      <b-col>
        <div class="row">
          <div class="col-2 text-center " v-for="doc in documentsByGroup" v-bind:key="doc.id">
            <div class="d-inline-block file" @click="onDownloadFileRequest(doc.id)">
              <i class="file-icon" :class="iconByExt(doc.realName)"></i>
              <p class="file-name">{{ doc.realName }}</p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'clientDocuments',
  props: { clientId: Number },
  data: () => ({
    loaded: false,
    error: null,
    tabIndex: 0,
    documents: [],
    selectedTypes:[],
    upload: {
      file: null,
      group: null,
    },
    listeners: {
      load: () => {
      }
    },
    newType:false,
  }),
  watch: {
    clientId() {
      this.refresh()
    },
    documentsByGroup() {
      let group = Object.keys(this.documentsByGroup)[this.tabIndex]
      this.upload.group = (group == 'others' ? '' : group)
    },
    loaded() {
      this.$forceUpdate()
    }
  },
  created() {
    let $this = this
    this.$root.$on('crm::client::documents::load', (this.listeners.load = () => {
      $this.refresh()
    }))
  },

  beforeDestroy() {
    this.$root.$off('crm::client::documents::load', this.listeners.load)
  },
  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    checkAllTypes: {
      get() {
        return this.selectedTypes.length === this.documentGroups.length
      },
      set(v) {
        if (v === false) {
          return
        }
        this.selectedTypes = [...this.documentGroups];
      }
    },
    documentGroups(){
      if (!Array.isArray(this.documents)) {
        return []
      }

      return Array.from(new Set(this.documents.map(doc => doc.group == null || doc.group === "" ? this.msg("Client") : doc.group )));
    },
    documentsByGroup() {
      if (!Array.isArray(this.documents)) {
        return []
      }
      return this.documents.filter(doc => this.selectedTypes.includes(doc.group == null || doc.group === "" ? this.msg("Client") : doc.group) )
    }
  },
  methods: {
    ...mapActions('document', {
      _uploadFile: 'uploadFile',
      _getDocuments: 'getClientDocuments',
      _downloadFile: 'downloadFile'
    }),
    refresh() {
      this.loaded = false
      this.documents = []
      if (isNaN(this.clientId) || parseInt(this.clientId) <= 0) {
        this.loaded = false
        this.error = this.msg('Invalid client')
        return
      }
      this.error = null
      this._getDocuments(this.clientId)
          .then((docs) => {
            this.documents = docs
            this.loaded = true
            this.checkAllTypes = true;
          }, (e) => {
            this.loaded = false
            this.error = e
          })
    },
    iconByExt(file) {
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return { far: true, 'fa-file-pdf': true }
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return { far: true, ' fa-file-image': true }
      }
      if (ext === 'doc' || ext === 'docx' ){
        return { far: true, ' fa-file-word': true }
      }
      if (ext === 'xls' || ext === 'xlsx' ){
        return { far: true, ' fa-file-word': true }
      }
      if (ext === 'zip' || ext === 'rar' ||  ext === '7zip'){
        return { far: true, ' fa-file-archive': true }
      }
      return { fas: true, 'fa-file-alt': true }
    },
    uploadFile() {
      let $this = this
      this._uploadFile({
        ...this.upload,
        clientId: this.clientId
      })
          .then(doc => {
            $this.documents.push(doc)
            $this.upload = {
              file: null,
              group: null,
            }
            $this.$root.$emit('bv::hide::popover')
          })
    },
    onDownloadFileRequest(fileId) {
      let $this = this;
      this._downloadFile({fileId:fileId,clientId:this.clientId}).then( file => {
        $this.asyncDownloadFile(file)
      })
    }
  },
}
</script>

<style scoped>
#clientsDocuments .file {
  cursor: pointer;
}

#clientsDocuments .file .file-icon {
  font-size: 36px;
}

#clientsDocuments .file .file-name {
  margin-top: 5px;
}
</style>
