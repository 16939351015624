<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}

    </span>

<!--    <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with-->
<!--      <feather-icon-->
<!--        icon="HeartIcon"-->
<!--        size="21"-->
<!--        class="text-danger stroke-current"-->
<!--      />-->
<!--    </span>-->
  </p>
</template>

<script>

export default {

}
</script>
