<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumbs"
                :key="item.text"
                :active="item.active"
                  :to="item.to"
              >
                <span class="text-capitalize">{{ msg(item.text) }}</span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <portal-target name="breadcrumb-right"></portal-target>
    </b-col>
  </b-row>
</template>

<script>

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  computed:{
    breadcrumbs(){
      let breadcrumbs =  this.$route.meta.breadcrumb;
      let vars = Object.keys(this.$route.params);
      return breadcrumbs;// breadcrumbs.map(breadcrumb => vars.reduce((p,c) => p.replace("/["+c+"]/",c), breadcrumb))
    }
  }

}
</script>
