<template>
  <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
  >
    <template #button-content>
      <!--      badge="6"-->
      <feather-icon badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21"/>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ msg('Notifications') }}
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          0 {{ msg('New') }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >


      <h4 class="notification-title mb-0 p-2 text-center mr-auto">
        {{ msg('No new notifications') }}
      </h4>


    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
          variant="primary"
          block
          disabled
      >{{ msg('Read all notifications') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {mapActions} from "vuex";

export default {
  components: {VuePerfectScrollbar},
  setup() {
    /* eslint-disable global-require */
    const notifications = []
    /* eslint-disable global-require */

    const systemNotifications = []

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },

}
</script>

<style>

</style>
